import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Subtitles,
    Chat,
    pubnub,
    TextToSpeech,
    SpeechToText,
    MainWrapper,
    useStateValue,
} from 'wwtc-sdk';
import { SitePal } from 'sitepal-react-v2';
import {
    endpointSpeechToText,
    endpointTextToSpeech,
    endpointTranslate,
    endpointDetectLanguage,
    signedEndpoint,
} from '../constants/connection';
import { Download, Movie, Desktop, Times, Users, User, Share } from './svg/icons';
import { handlePreRequestSubtitles } from './utils';
import buildSubtitlesFile from '../utils/subtitles';
import { getAvatarVoice } from '../utils/avatar';
import specialLanguages from '../constants/specialLanguages';
import Call from './Call';

function Main() {
    // This values come from Login components
    // Also could set up constants values
    // This values are required like props of the MainWrapper
    const history = useHistory();
    const [isModerator, setIsModerator] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalClipboard, setModalClipboard] = useState(false);
    const [mode, setMode] = useState('normal');

    const { userName, language, languageAux, gender, room, apiToken, userInvite, roomType } =
        history.location.state;

    console.log(history.location.state, apiToken);
    useEffect(() => {
        setIsModerator(!userInvite);

        pubnub.addListener({
            signal: function (s) {
                if (s.message === 'logout_admin' && !isModerator) {
                    pubnub.unsubscribeAll();
                    history.goBack();
                }
            },
        });
        pubnub.setState({
            state: { role: 'moderator' },
            channels: [room],
        });

        return () => {};
    }, []);

    return (
        <MainWrapper
            autologin={false}
            gender={gender}
            language={language}
            roomName={room}
            userName={userName}
        >
            <Header
                pubnub={pubnub}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                setModalClipboard={setModalClipboard}
                isModerator={isModerator}
                roomType={roomType}
                mode={mode}
                setMode={setMode}
            />
            {mode === 'normal' ? (
                <WrapperComponents
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    modalClipboard={modalClipboard}
                    setModalClipboard={setModalClipboard}
                    setIsModerator={setIsModerator}
                    userInvite={userInvite}
                    apiToken={apiToken}
                    language={language}
                    languageAux={languageAux}
                />
            ) : (
                <Call />
            )}
        </MainWrapper>
    );
}

function Header(props) {
    // initName comes from autologin component
    const {
        pubnub,
        initName,
        setModalVisible,
        setModalClipboard,
        conferenceName,
        isModerator,
        roomType,
        mode,
        setMode,
    } = props;
    const history = useHistory();

    const handleLogout = async () => {
        if (pubnub) {
            if (isModerator) {
                await pubnub.signal({
                    message: 'logout_admin',
                    channel: conferenceName,
                });
            }
            pubnub.unsubscribeAll();
            history.goBack();
        } else {
            history.goBack();
        }
    };

    return (
        <header>
            <div className="header-left-section">
                <div className="info-user">
                    <img src={`${process.env.PUBLIC_URL}/logo-header.png`} alt="WWTC" />
                </div>
            </div>
            <div className="header-center-section">
                <div className="direct-buttons hidden-mobile">
                    <span>Now Translate</span>
                </div>
            </div>
            <div className="header-right-section">
                <div className="settings-user">
                    <span
                        className="call-btn"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        data-container="body"
                        onClick={() => (mode === 'call' ? setMode('normal') : setMode('call'))}
                    >
                        {mode === 'call' ? (
                            <i className="fas fa-laptop"></i>
                        ) : (
                            <i className="fas fa-phone"></i>
                        )}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {isModerator && (
                        <span
                            className="share-btn"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Share room"
                            data-container="body"
                            onClick={() => setModalClipboard(true)}
                        >
                            <Share />
                        </span>
                    )}
                    <span className="participants-label hidden-mobile">Roll Call</span>&nbsp;
                    <span
                        className="participants-btn"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Participants"
                        data-container="body"
                        onClick={() => setModalVisible(true)}
                    >
                        <Users />
                    </span>
                    &nbsp;&nbsp;
                    <span className="profile-name hidden-mobile">{initName}</span>
                    &nbsp;&nbsp;
                    <span
                        className="logout-btn"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Logout"
                        data-container="body"
                        onClick={handleLogout}
                    >
                        <i className="fas fa-sign-out-alt"></i>
                    </span>
                </div>
            </div>
        </header>
    );
}

function WrapperComponents(props) {
    // values from autologin
    const jitsiContainer = 'jitsi-container';
    const widthViewport = document.getElementsByTagName('body')[0].offsetWidth;
    const isMobile = widthViewport <= 600;

    const {
        initName,
        initLanguage,
        initGender,
        conferenceName,
        modalVisible,
        setModalVisible,
        modalClipboard,
        setModalClipboard,
        setIsModerator,
        userInvite,
        apiToken,
        language,
        languageAux,
    } = props;
    const [layout, setLayout] = useState(2);
    const [jitsi, setJitsi] = useState({});
    const [oldSubtitles, setOldSubtitles] = useState([]);
    const [fontSizeSubtitles, setFontSizeSubtitles] = useState(2);
    const refSubtitles = useRef(null);
    const [{ subtitles: subtitlesComponent }] = useStateValue();
    const [isBuildingPdf, setIsBuildingPdf] = useState(false);
    const [modalDownload, setModalDownload] = useState(false);

    const [sayAudio, setSayAudio] = useState(null);
    const [sayText, setSayText] = useState(null);
    const [silentCalled, setsilentCalled] = useState(false);

    const isSafari = navigator.userAgent.includes('Safari');
    const isCrios = navigator.userAgent.includes('CriOS');
    const isChrome = navigator.userAgent.includes('Chrome');

    const emptyJitsi = Object.keys(jitsi).length === 0 && jitsi.constructor === Object;
    useEffect(() => {
        handleAudioVideo();
        // if (!emptyJitsi) {
        //     return () => jitsi.dispose();
        // }
    }, []);

    const isSpecialLanguage = (subtitles) => {
        const specialSubtitle = specialLanguages.find((special) => {
            var regex = new RegExp(special, 'g');
            var atLeastOneMatches = subtitles.some((e) => regex.test(e.speakerLanguage));
            return atLeastOneMatches;
        });

        return !!specialSubtitle;
    };

    const downloadSubtitlesPDF = (subtitles) => {
        setIsBuildingPdf(true);
        const languageBilingual = subtitlesComponent.sourceLanguage.value;
        const contentPDF = buildSubtitlesFile(subtitles, languageBilingual);

        // detect special languages
        const subtitlesWithSpecialLanguages = isSpecialLanguage(subtitles);

        // download unicode typography
        if (subtitlesWithSpecialLanguages) {
            window.pdfMake.fonts = {
                ArialUnicode: {
                    normal: 'https://sdkresources.s3.amazonaws.com/ArialUnicodeMSFont.ttf',
                    bold: 'https://sdkresources.s3.amazonaws.com/ArialUnicodeMSFont.ttf',
                    italics: 'https://sdkresources.s3.amazonaws.com/ArialUnicodeMSFont.ttf',
                },
            };
        }

        var docDefinition = {
            content: contentPDF,
            defaultStyle: {
                font: subtitlesWithSpecialLanguages ? 'ArialUnicode' : 'Roboto',
                fontSize: 12,
            },
        };

        window.pdfMake.createPdf(docDefinition).download(() => {
            setIsBuildingPdf(false);
        });
    };

    const fetchTextToSpeechCustom = async (endpoint, text, sourceLanguage, voice, itemTtsCall) => {
        const avatarVoice = getAvatarVoice(sourceLanguage);
        setSayText([text, ...avatarVoice]);
        return {
            indexCall: itemTtsCall,
            error: 'using VHC',
        };
        try {
            let response = await fetch(endpoint.url.replace(/:sourceLanguage/g, sourceLanguage), {
                method: endpoint.method,
                redirect: 'follow',
                body: JSON.stringify({
                    text,
                }),
            });
            let data = await response.json();
            try {
                var arrayBuffer = _base64ToArrayBuffer(data.audio);
                let blob = new Blob([arrayBuffer], { type: 'audio/wav' });
                const downloadUrl = window.webkitURL.createObjectURL(blob);
                return { downloadUrl, indexCall: itemTtsCall };
            } catch (e) {
                return {
                    indexCall: itemTtsCall,
                    error: e,
                };
            }
        } catch (err) {
            return {
                indexCall: itemTtsCall,
                error: err,
            };
        }
    };

    const _base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    const initialiseJitsi = async () => {
        const _jitsi = new window.JitsiMeetExternalAPI('curiosity.worldwidetechconnections.com', {
            parentNode: document.getElementById(jitsiContainer),
            roomName: conferenceName,
            userInfo: {
                displayName: initName,
            },
            configOverwrite: {
                enableWelcomePage: false,
                enableClosePage: true,
                startWithAudioMuted: false,
                startWithVideoMuted: false,
                hideConferenceSubject: true,
                hideConferenceTimer: true,
            },
            interfaceConfigOverwrite: {
                TOOLBAR_BUTTONS: [
                    ...(userInvite ? [] : ['security', 'recording']),
                    'microphone',
                    'camera',
                    'closedcaptions',
                    'desktop',
                    'embedmeeting',
                    'fullscreen',
                    'highlight',
                    'participants-pane',
                    'videobackgroundblur',
                    'select-background',
                    'sharedvideo',
                    'shareaudio',
                    'fodeviceselection',
                    'hangup',
                    'profile',
                    'stats',
                    'etherpad',
                    'settings',
                    'raisehand',
                    'videoquality',
                    'filmstrip',
                    'download',
                    'help',
                    'mute-everyone',
                    'tileview',
                ],
            },
        });

        _jitsi.addEventListener('participantLeft', (val) => console.log(val));
        /*_jitsi.addEventListener('screenSharingStatusChanged', (obj) => {
            if (obj.on) {
                const baseUrl = window.location.origin;
                const url = `${baseUrl}/chat?n=${initName}-screenshare&id=${conferenceName}&lng=${initLanguage}&g=${initGender}`;
                window.open(
                    url,
                    '_blank',
                    `toolbar=0,location=0,menubar=0,left=5000px,width=480,height=${window.innerHeight}`,
                );
            }
        });*/
        _jitsi.addEventListener('readyToClose', () => {
            setOldSubtitles(refSubtitles.current.state.subtitles);
            const container = document.getElementById(jitsiContainer);
            container.removeChild(container.firstChild);
            setJitsi({});
            if (!isMobile) {
                setLayout(2);
            }
        });

        _jitsi.addEventListener('participantRoleChanged', (data) => {
            if (data.role === 'moderator') {
                setIsModerator(true);
            } else {
                setIsModerator(false);
            }
        });

        setJitsi(_jitsi);
    };

    const handleLayout = () => {
        setOldSubtitles(refSubtitles.current.state.subtitles);
        if (layout === 1) {
            setFontSizeSubtitles(2);
            return setLayout(2);
        }
        setFontSizeSubtitles(1);
        setLayout(1);
    };

    const handleAudioVideo = async () => {
        setOldSubtitles(refSubtitles.current.state.subtitles);
        if (layout === 2) {
            setFontSizeSubtitles(1);
            await setLayout(1);
        }
        //initialiseJitsi();
    };

    const renderSubtitles = () => {
        return (
            <Subtitles.SubtitlesUI
                ref={refSubtitles}
                username={initName}
                initialSourceLanguage={initLanguage}
                initialTargetLanguage={initLanguage} //change this to spanish-international when you want to translate to spanish by default
                initialVisible={true}
                endpoint={signedEndpoint(apiToken, endpointTranslate)}
                provider="pubnub"
                speechRecognition={true}
                ttsOption={true}
                showButtonCloseComponent={false}
                showLanguageServices={true}
                channel={conferenceName}
                defaultBilingual={true}
                showFlipButton={true}
                style={
                    layout === 1
                        ? stylesWrapper.subtitlesContainer
                        : stylesWrapper.fullSubtitlesContainer
                }
                preRequestSubtitles={handlePreRequestSubtitles}
                render={(subtitles) => (
                    <div style={{ display: 'flex' }}>
                        <button
                            className="a-element"
                            onClick={() => downloadSubtitlesPDF(subtitles)}
                        >
                            <Download />
                        </button>
                        {emptyJitsi ? (
                            <button className="a-element" onClick={handleLayout}>
                                <Movie />
                            </button>
                        ) : null}
                    </div>
                )}
                subtitlesParent={oldSubtitles}
                fontSizeSubtitles={fontSizeSubtitles}
            />
        );
    };

    const renderJitsiFrame = () => {
        return (
            <div
                id="jitsi-container"
                style={
                    layout === 1
                        ? stylesWrapper.fullSubtitlesContainer
                        : stylesWrapper.subtitlesContainer
                }
            >
                {emptyJitsi ? (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <button
                            className="Button Button-primary"
                            onClick={handleAudioVideo}
                            style={{ padding: '0.9em 1em' }}
                        >
                            <div style={{ display: 'block', marginBottom: '.5em' }}>
                                <Desktop />
                            </div>
                            <p style={{ marginBottom: 0, textTransform: 'capitalize' }}>
                                enable audio/video
                            </p>
                        </button>
                    </div>
                ) : null}
            </div>
        );
    };

    const subtitlesRendered = renderSubtitles();
    return (
        <div className="wrapper-components">
            <div className="wrapper-left">
                {/* <div className="loading-jitsi">
                    <p>Loading...</p>
                </div> */}
                {layout === 1 ? (
                    <div id="jitsi-container">
                        <SitePal
                            embed='6964111,300,500,"",1,0,2770691,0,1,0,"Pj2cDUUl3VXfT77Fxe0nql8bPwEHW0jh",0,1'
                            sayAudio={sayAudio}
                            sayText={sayText}
                        />
                    </div>
                ) : (
                    subtitlesRendered
                )}
            </div>
            <div className="wrapper-right">
                <Chat.ChatUI
                    attachFile={true}
                    channel={conferenceName}
                    endpoint={endpointTranslate}
                    fontSizeChat={1}
                    initialSourceLanguage={initLanguage}
                    initialTargetLanguage={initLanguage}
                    initialVisible={true}
                    provider="pubnub"
                    style={stylesWrapper.chatContainer}
                    showButtonCloseComponent={false}
                    speechRecognition={true}
                    username={initName}
                />
                {layout === 1 ? subtitlesRendered : renderJitsiFrame()}
            </div>
            <SpeechToText.SpeechToTextUI
                username={initName}
                initialLanguage={initLanguage}
                initialVisible={false}
                initialActive={false}
                withUI={false} // to use only speech recognition
                endpoint={endpointSpeechToText}
                detectLanguage={true}
                endpointDetectLanguage={signedEndpoint(apiToken, endpointDetectLanguage)}
                paramsDetectLanguage={[language, languageAux]}
            />
            <TextToSpeech.TextToSpeechUI
                initialVendor="readspeaker"
                initialLanguage={initLanguage}
                initialGender={initGender}
                initialVisible={false}
                initialActive={false}
                withUI={false} // to use only audio player
                endpoint={endpointTextToSpeech}
                fetchCustom={fetchTextToSpeechCustom}
            />
            {modalVisible ? (
                <ModalParticipants
                    closeModal={() => setModalVisible(false)}
                    pubnub={pubnub}
                    conferenceName={conferenceName}
                />
            ) : null}
            {modalClipboard ? (
                <ModalClipboard
                    closeModal={() => setModalClipboard(false)}
                    pubnub={pubnub}
                    conferenceName={conferenceName}
                />
            ) : null}
            {isBuildingPdf ? <ModalDownload onCloseModal={() => setModalDownload(false)} /> : null}
        </div>
    );
}

const ModalParticipants = (props) => {
    const { closeModal, pubnub, conferenceName } = props;
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        pubnub
            .hereNow({
                channels: [conferenceName],
                includeState: true,
            })
            .then(async (res) => {
                let participants = [];
                const participantsInChannel = res.channels[conferenceName].occupants;

                for (let index = 0; index < participantsInChannel.length; index++) {
                    const item = participantsInChannel[index];
                    const { data } = await pubnub.objects.getUUIDMetadata({
                        uuid: item.uuid,
                    });
                    participants.push(data);
                }
                setParticipants(participants);
            });
    }, []);

    const downloadParticipants = () => {
        var contentPDF = [];
        contentPDF.push({
            text: `${conferenceName} - Roll Call`,
            style: 'header',
            bold: true,
        });
        participants.forEach((item, index) => {
            contentPDF.push({
                ul: [{ text: item.name, bold: false }],
            });
        });

        var docDefinition = {
            content: contentPDF,
            defaultStyle: {
                fontSize: 12,
            },
        };
        window.pdfMake.createPdf(docDefinition).download('rollCallWWTC.pdf');
    };

    return (
        <div style={stylesWrapper.containerModal}>
            <div style={stylesWrapper.wrapperModal}>
                <div className="header-container">
                    <div className="header-title">
                        <span>Roll Call</span>
                    </div>
                    <div className="header-options">
                        <button className="a-element" onClick={downloadParticipants}>
                            <Download />
                        </button>
                        <button className="a-element" onClick={closeModal}>
                            <Times />
                        </button>
                    </div>
                </div>
                <div className="body-container" style={stylesWrapper.bodyModal}>
                    {participants.map((item, index) => {
                        return (
                            <div key={index} style={stylesWrapper.participantItem}>
                                <span>
                                    <User />
                                </span>
                                &nbsp;&nbsp;
                                <p style={{ margin: 0, textIndent: 0 }}>{item.name}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const ModalClipboard = (props) => {
    const { closeModal, pubnub, conferenceName } = props;
    const [isCopied, setIsCopied] = useState(false);

    const buildInvitationLink = () => {
        const baseUrl = window.location.origin;
        const hostId = pubnub.getUUID();
        const url = `${baseUrl}/invitation?room=${conferenceName}&hostId=${hostId}`;
        return url;
    };

    const copyLinkToClipboard = () => {
        // Create a "hidden" input
        var aux = document.createElement('input');
        var url = buildInvitationLink();

        // Assign it the value of the specified element
        aux.setAttribute('value', url);

        // Append it to the body
        document.body.appendChild(aux);

        // Highlight its content
        aux.select();

        // Copy the highlighted text
        document.execCommand('copy');

        // Remove it from the body
        document.body.removeChild(aux);

        setIsCopied(true);
    };

    const link = buildInvitationLink();

    return (
        <div style={stylesWrapper.containerModal}>
            <div style={{ ...stylesWrapper.wrapperModal, height: 250, width: 450 }}>
                <div className="header-container">
                    <div className="header-title">
                        <span>Share room</span>
                    </div>
                    <div className="header-options">
                        <button className="a-element" onClick={closeModal}>
                            <Times />
                        </button>
                    </div>
                </div>
                <div
                    className="body-container"
                    style={{ ...stylesWrapper.bodyModal, textAlign: 'center' }}
                >
                    <p>Invitation link</p>
                    <p style={{ fontSize: 14 }}>{link}</p>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 48,
                        }}
                    >
                        <button
                            type="button"
                            className="Button Button-small Button-primary"
                            onClick={copyLinkToClipboard}
                        >
                            {isCopied ? 'Copied' : 'Copy to clipboard'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ModalDownload = ({ onCloseModal }) => {
    return (
        <div style={stylesWrapper.containerModal}>
            <div style={{ ...stylesWrapper.wrapperModal, height: 180, width: 450 }}>
                <div className="header-container">
                    <div className="header-title">
                        <span>PDF Subtitles</span>
                    </div>
                    <div className="header-options"></div>
                </div>
                <div
                    className="body-container"
                    style={{ ...stylesWrapper.bodyModal, textAlign: 'center' }}
                >
                    <p>Downloading PDF file...</p>
                    <p style={{ fontSize: 14 }}>Please wait, this could take a few seconds.</p>
                </div>
            </div>
        </div>
    );
};

const stylesWrapper = {
    fullSubtitlesContainer: {
        width: 'calc(100% - 10px)',
        height: 'calc(100% - 10px)',
        margin: '5px auto',
        position: 'relative',
        zIndex: 20,
    },
    subtitlesContainer: {
        width: 'calc(100% - 10px)',
        height: 'calc(50% - 10px)',
        margin: '5px auto',
    },
    chatContainer: {
        width: 'calc(100% - 10px)',
        height: 'calc(50% - 10px)',
        margin: '5px auto',
    },
    containerModal: {
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100vh - 50px)',
        width: '100vw',
        background: 'rgba(1,1,1,0.3)',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 2000,
    },
    bodyModal: {
        marginTop: 15,
        height: 'calc(100% - 30px)',
        overflow: 'auto',
    },
    wrapperModal: {
        padding: '10px 5px',
        backgroundColor: 'white',
        borderRadius: 5,
        height: 500,
        top: 'calc(50% - 250px)',
        width: 320,
        position: 'absolute',
        WebkitBoxShadow: '4px 4px 9px -1px rgba(0,0,0,0.75)',
        MozBoxShadow: '4px 4px 9px -1px rgba(0,0,0,0.75)',
        boxShadow: '4px 4px 9px -1px rgba(0,0,0,0.75)',
    },
    participantItem: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '10px 0',
        cursor: 'default',
        textIndent: '1em',
    },
};

export default Main;
